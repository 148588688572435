export const ALFAROMEO = [
    {id: 1, label: "Giulietta"},
    {id: 2, label: "Mito"},
    {id: 3, label: "147"},
    {id: 4, label: "GT"},
    {id: 5, label: "159"},
    {id: 6, label: "Brera"},
    {id: 7, label: "8C Competizione"},
    {id: 8, label: "4C"},
    {id: 9, label: "Giulia"},
    {id: 10, label: "Stelvio"},
    {id: 11, label: "Tonale"}
]

export const AUDI = [
    {id: 1, label: "A1"},
    {id: 2, label: "A3"},
    {id: 3, label: "A4"},
    {id: 4, label: "A5"},
    {id: 5, label: "A6"},
    {id: 6, label: "A7"},
    {id: 7, label: "A8"},
    {id: 8, label: "e-Tron GT"},
    {id: 9, label: "TT"},
    {id: 10, label: "R8"},
    {id: 11, label: "Q2"},
    {id: 12, label: "Q3"},
    {id: 13, label: "Q4 e-Tron"},
    {id: 14, label: "Q5"},
    {id: 15, label: "Q5 e-Tron"},
    {id: 16, label: "Q6"},
    {id: 17, label: "Q7"},
    {id: 18, label: "Q8"},
    {id: 19, label: "RS5"},
    {id: 20, label: "S8"},
    {id: 21, label: "S3"},
    {id: 22, label: "S7"},
    {id: 23, label: "S5"},
    {id: 24, label: "RS6"},
    {id: 25, label: "RS7"},
    {id: 26, label: "S1"},
    {id: 27, label: "TT RS"},
    {id: 28, label: "S5"},
    {id: 29, label: "RS5"}
]
 
export const BAIC = [
    {id: 1, label: "EU5"},
    {id: 2, label: "U5 PLUS"},
    {id: 3, label: "X35"},
    {id: 4, label: "X55"},
    {id: 5, label: "X55-II"},
    {id: 6, label: "X7"},
    {id: 7, label: "BJ40 PLUS"},
    {id: 8, label: "BJ40 SE"},
    {id: 9, label: "BJ40 OFFROADER"},
    {id: 10, label: "BJ80"},
    {id: 11, label: "F40"}
]

export const BMW = [
    {id: 1, label: "1 Series"},
    {id: 2, label: "2 Series"},
    {id: 3, label: "3 Series"},
    {id: 4, label: "4 Series"},
    {id: 5, label: "5 Series"},
    {id: 6, label: "6 Series"},
    {id: 7, label: "7 Series"},
    {id: 8, label: "8 Series"},
    {id: 9, label: "X1"},
    {id: 10, label: "X2"},
    {id: 11, label: "X3"},
    {id: 12, label: "X4"},
    {id: 13, label: "X5"},
    {id: 14, label: "X6"},
    {id: 15, label: "X7"},
    {id: 16, label: "XM"},
    {id: 17, label: "Z4"},
    {id: 18, label: "I3"},
    {id: 19, label: "I4"},
    {id: 20, label: "I7"},
    {id: 21, label: "IX1"},
    {id: 22, label: "IX3"},
    {id: 23, label: "IX"}
]

export const CHANGAN = [
    {id: 1, label: "CS75"},
    {id: 2, label: "CS15"},
    {id: 3, label: "CS35"},
    {id: 4, label: "Alsvin"},
    {id: 5, label: "CX30"},
    {id: 6, label: "CX20"},
    {id: 7, label: "Eado"},
    {id: 8, label: "Benben"}
]

export const CHERY = [
    {id: 1, label: "Arrizo 5"},
    {id: 2, label: "E3"},
    {id: 3, label: "TIGGO8"},
    {id: 4, label: "Arrizo7"},
    {id: 5, label: "E5"},
    {id: 6, label: "A5"},
    {id: 7, label: "Fulwin 2"},
    {id: 8, label: "A1"},
    {id: 9, label: "QQ3"},
    {id: 10, label: "A15"},
    {id: 11, label: "A11"},
    {id: 12, label: "Tiggo 5"},
    {id: 13, label: "V5"},
    {id: 14, label: "A3"},
    {id: 15, label: "S22L"},
    {id: 16, label: "Q22"},
    {id: 17, label: "X1"},
    {id: 18, label: "Tiggo"}
]

export const CHEVROLET = [
    {id: 1, label: "Blazer"},
    {id: 2, label: "Blazer EV"},
    {id: 3, label: "Bolt EUV"},
    {id: 4, label: "Captiva"},
    {id: 5, label: "Equinox"},
    {id: 6, label: "Equinox EV"},
    {id: 7, label: "Groove"},
    {id: 8, label: "Tracker"},
    {id: 9, label: "Trailblazer"},
    {id: 10, label: "Traverse"},
    {id: 11, label: "Trax"},
    {id: 12, label: "Seeker"},
    {id: 13, label: "Suburban"},
    {id: 14, label: "Tahoe"},
    {id: 15, label: "Colorado"},
    {id: 16, label: "Montana"},
    {id: 17, label: "S10 Max"},
    {id: 18, label: "Silverado"},
    {id: 19, label: "Silverado EV"},
    {id: 20, label: "Camaro"},
    {id: 21, label: "Corvette"},
    {id: 22, label: "Cruze"},
    {id: 23, label: "Malibu"},
    {id: 24, label: "Monza"},
    {id: 25, label: "Onix Plus"},
    {id: 26, label: "Optra"},
    {id: 27, label: "Sail"},
    {id: 28, label: "Bolt"},
    {id: 29, label: "Cruze"},
    {id: 30, label: "Menlo"},
    {id: 31, label: "Onix"},
    {id: 32, label: "Spark"},
    {id: 33, label: "Orlando"},
    {id: 34, label: "Spin"},
    {id: 35, label: "Express"},
    {id: 36, label: "N300"},
    {id: 37, label: "N400"}
]

export const CHRYSLER = [
    {id: 1, label: "Crossfire"},
    {id: 2, label: "Delta"},
    {id: 3, label: "200"},
    {id: 4, label: "Ypsilon"},
    {id: 5, label: "Aspen"},
    {id: 6, label: "Pacifica"},
    {id: 7, label: "Voyager"},
    {id: 8, label: "Grand Voyager"},
    {id: 9, label: "Town and Country"}
]

export const CITROEN = [
    {id: 1, label: "Ami"},
    {id: 2, label: "C3"},
    {id: 3, label: "C3 (CC21)"},
    {id: 4, label: "C4 Sedan"},
    {id: 5, label: "C6 II"},
    {id: 6, label: "C-Elysée"},
    {id: 7, label: "SpaceTourer"},
    {id: 8, label: "Berlingo"},
    {id: 9, label: "C3 Aircross"},
    {id: 10, label: "C3-XR"},
    {id: 11, label: "C4"},
    {id: 12, label: "C4 Cactus"},
    {id: 13, label: "C5 X"},
    {id: 14, label: "C5 Aircross"},
    {id: 15, label: "Berlingo"},
    {id: 16, label: "Jumpy"},
    {id: 17, label: "Jumper"},
    {id: 18, label: "C-Crosser"},
    {id: 19, label: "C1"},
    {id: 20, label: "C3 Picasso"},
    {id: 21, label: "C3L"},
    {id: 22, label: "C4 Coupe"},
    {id: 23, label: "C4 Picasso"},
    {id: 24, label: "C4 Aircross"},
    {id: 25, label: "C-Triomphe"},
    {id: 26, label: "C5 Tourer"},
    {id: 27, label: "C8"},
    {id: 28, label: "E-Méhari"},
    {id: 29, label: "DS 3"},
    {id: 30, label: "DS 4"},
    {id: 31, label: "DS 5"},
    {id: 32, label: "Nemo"}
]

export const DFSK = [
    {id: 1, label: "C31"},
    {id: 2, label: "C32"},
    {id: 3, label: "K01h"},
    {id: 4, label: "Furgon"},
    {id: 5, label: "C35"}
]

export const DODGE = [
    {id: 1, label: "Attitude"},
    {id: 2, label: "Challenger"},
    {id: 3, label: "Charger"},
    {id: 4, label: "Durango"},
    {id: 5, label: "Hornet"},
    {id: 6, label: "Journey"},
]

export const DS = [
    {id: 1, label: "DS 3"},
    {id: 2, label: "DS 4"},
    {id: 3, label: "DS 7"},
    {id: 4, label: "DS 9"},
    {id: 5, label: "DS 4S"},
    {id: 6, label: "DS 5"},
    {id: 7, label: "DS 5LS"},
    {id: 8, label: "DS 6"},
]

export const FIAT = [
    {id: 1, label: "Doblò"},
    {id: 2, label: "Stilo"},
    {id: 3, label: "Albea"},
    {id: 4, label: "Ulysse Gen2"},
    {id: 5, label: "Idea"},
    {id: 6, label: "Panda Gen2"},
    {id: 7, label: "Panda 4x4 Gen2"},
    {id: 8, label: "Croma Gen2"},
    {id: 9, label: "Sedici"},
    {id: 10, label: "Grande Punto"},
    {id: 11, label: "Bravo"},
    {id: 12, label: "500"},
    {id: 13, label: "Linea"},
    {id: 14, label: "Fiorino / Qubo Gen3"},
    {id: 15, label: "500 C"},
    {id: 16, label: "Punto EVO"},
    {id: 17, label: "Doblò Gen2"},
    {id: 18, label: "Uno Gen2"},
    {id: 19, label: "Freemont"},
    {id: 20, label: "Palio Gen2"},
    {id: 21, label: "Panda Gen3"},
    {id: 22, label: "Grand Siena Gen2"},
    {id: 23, label: "500L"},
    {id: 24, label: "Viaggio"},
    {id: 25, label: "500e"},
    {id: 26, label: "Ottimo"},
    {id: 27, label: "500X"},
    {id: 28, label: "Tipo 4 puertas"},
    {id: 29, label: "Tipo 5 puertas"},
    {id: 30, label: "Tipo Station Wagon"},
    {id: 31, label: "124 Spider"},
    {id: 32, label: "Toro"},
    {id: 33, label: "Mobi"},
    {id: 34, label: "Fullback"},
    {id: 35, label: "Argo"},
    {id: 36, label: "Cronos"},
]

export const FORD = [
    {id: 1, label: "Fiesta"},
    {id: 2, label: "Focus"},
    {id: 3, label: "Escort"},
    {id: 4, label: "Mondeo"},
    {id: 5, label: "Taurus"},
    {id: 6, label: "GT"},
    {id: 7, label: "Mustang"},
    {id: 8, label: "Bronco"},
    {id: 9, label: "Bronco Sport"},
    {id: 10, label: "EcoSport"},
    {id: 11, label: "Edge"},
    {id: 12, label: "Equator"},
    {id: 13, label: "Escape"},
    {id: 14, label: "Kuga"},
    {id: 15, label: "Everest"},
    {id: 16, label: "Evos"},
    {id: 17, label: "Expedition"},
    {id: 18, label: "Explorer"},
    {id: 19, label: "Mustang Mach-E"},
    {id: 20, label: "Puma"},
    {id: 21, label: "Territory"},
    {id: 22, label: "F-Series"},
    {id: 23, label: "F-150 Lightning"},
    {id: 24, label: "Ranger"},
    {id: 25, label: "Maverick"},
]

export const HAVAL = [
    {id: 1, label: "H6"},
    {id: 2, label: "H9"},
    {id: 3, label: "M6/M6 Plus"},
    {id: 4, label: "F7/F7x"},
    {id: 5, label: "Big Dog"},
    {id: 6, label: "Jolion"},
    {id: 7, label: "Chitu"},
    {id: 8, label: "Shenshou"},
    {id: 9, label: "Cool Dog"},
    {id: 10, label: "Xiaolong"},
    {id: 11, label: "Xiaolong Max"},
]

export const HONDA = [
    {id: 1, label: "Brio"},
    {id: 2, label: "City"},
    {id: 3, label: "Civic/Integra"},
    {id: 4, label: "E"},
    {id: 5, label: "Fit/Jazz/Life"},
    {id: 6, label: "Accord"},
    {id: 7, label: "Amaze"},
    {id: 8, label: "Crider"},
    {id: 9, label: "Envix"},
    {id: 10, label: "Elysion"},
    {id: 11, label: "Freed"},
    {id: 12, label: "Mobilio"},
    {id: 13, label: "Odyssey"},
    {id: 14, label: "Stepwgn"},
    {id: 15, label: "Avancier"},
    {id: 16, label: "BR-V"},
    {id: 17, label: "Breeze"},
    {id: 18, label: "CR-V"},
    {id: 19, label: "Elevate"},
    {id: 20, label: "HR‑V/Vezel/XR‑V"},
    {id: 21, label: "Passport"},
    {id: 22, label: "Pilot"},
]

export const HYUNDAI = [
    {id: 1, label: "HB20"},
    {id: 2, label: "i10"},
    {id: 3, label: "i20"},
    {id: 4, label: "i30"},
    {id: 5, label: "Accent/Verna"},
    {id: 6, label: "Aura/Grand i10 Sedan"},
    {id: 7, label: "Celesta"},
    {id: 8, label: "Elantra/Avante/i30 Sedan"},
    {id: 9, label: "Grandeur/Azera"},
    {id: 10, label: "HB20S"},
    {id: 11, label: "Ioniq 6"},
    {id: 12, label: "Lafesta"},
    {id: 13, label: "Mistra"},
    {id: 14, label: "Sonata"},
    {id: 15, label: "Alcazar/Grand Creta"},
    {id: 16, label: "Bayon"},
    {id: 17, label: "Casper"},
    {id: 18, label: "Creta/ Cantus"},
    {id: 19, label: "Exter"},
    {id: 20, label: "HR‑V/Vezel/XR‑V"},
    {id: 21, label: "Ioniq 5"},
    {id: 22, label: "Kona/Kauai"},
    {id: 23, label: "Mufasa"},
    {id: 24, label: "Nexo"},
    {id: 25, label: "Palisade"},
    {id: 26, label: "Santa Fe"},
    {id: 27, label: "Tucson"},
    {id: 28, label: "Venue"},
    {id: 29, label: "Santa Cruz"},
    {id: 30, label: "Staria"},
    {id: 31, label: "Custo/Custin"},
    {id: 32, label: "Stargazer"},
]

export const JAC = [
    {id: 1, label: "J2"},
    {id: 2, label: "J3"},
    {id: 3, label: "J5"},
    {id: 4, label: "J6"},
    {id: 5, label: "T40"},
    {id: 6, label: "T6"},
    {id: 7, label: "Heyue"},
    {id: 8, label: "Refine"},
    {id: 9, label: "iEV"},
    {id: 10, label: "Shuailing"},
    {id: 11, label: "Junling"},
    {id: 12, label: "HY"},
    {id: 13, label: "HFC1020KW1"},
    {id: 14, label: "HFC1027K1R"},
    {id: 15, label: "4R3"},
    {id: 16, label: "HFC1020K"},
]

export const JEEP = [
    {id: 1, label: "Wagoneer"},
    {id: 2, label: "Wrangler"},
    {id: 3, label: "Avenger"},
    {id: 4, label: "Cherokee"},
    {id: 5, label: "Compass"},
    {id: 6, label: "Commander"},
    {id: 7, label: "Grand Cherokee"},
    {id: 8, label: "Renegade"},
    {id: 9, label: "Gladiator"},
    {id: 10, label: "Cj"},
    {id: 11, label: "Willys Wagon"},
    {id: 12, label: "Willys Pickup"},
    {id: 13, label: "Vj"},
    {id: 14, label: "Dj"},
    {id: 15, label: "Fc"},
    {id: 16, label: "Sj"},
    {id: 17, label: "C101"},
    {id: 18, label: "C104"},
    {id: 19, label: "Xj"},
    {id: 20, label: "Mj"},
    {id: 21, label: "Yj"},
    {id: 22, label: "Zj"},
    {id: 23, label: "Tj"},
    {id: 24, label: "Lj"},
    {id: 25, label: "Wj"},
    {id: 26, label: "Kj"},
    {id: 27, label: "Wk"},
    {id: 28, label: "Xk"},
    {id: 29, label: "Jk"},
    {id: 30, label: "Jku"},
    {id: 31, label: "Mk"},
    {id: 32, label: "Mp"},
    {id: 33, label: "Kk"},
    {id: 34, label: "Wk2"},
    {id: 35, label: "Kl"},
    {id: 36, label: "Bu"},
    {id: 37, label: "Jl"},
    {id: 38, label: "Jt"},
]

export const KIA = [
    {id: 1, label: "Ceed"},
    {id: 2, label: "Forte"},
    {id: 3, label: "Picanto"},
    {id: 4, label: "Ray"},
    {id: 5, label: "Rio"},
    {id: 6, label: "K5"},
    {id: 7, label: "K7"},
    {id: 8, label: "K8"},
    {id: 9, label: "K9"},
    {id: 10, label: "Pegas"},
    {id: 11, label: "Stinger"},
    {id: 12, label: "Soul"},
    {id: 13, label: "EV6"},
    {id: 14, label: "KX5"},
    {id: 15, label: "Mohave"},
    {id: 16, label: "Niro"},
    {id: 17, label: "Seltos"},
    {id: 18, label: "Sonet"},
    {id: 19, label: "Sorento"},
    {id: 20, label: "Sportage"},
    {id: 21, label: "Stonic"},
    {id: 22, label: "Telluride"},
    {id: 23, label: "XCeed"},
    {id: 24, label: "Carens"},
    {id: 25, label: "Carnival"},
    {id: 26, label: "Concord/Capital"},
    {id: 27, label: "K4"},
    {id: 28, label: "KX7"},
    {id: 29, label: "Opirus/Amanti"},
    {id: 30, label: "Qianlima"},
    {id: 31, label: "Rondo"},
    {id: 32, label: "Venga"},
    {id: 33, label: "Visto"},
]

export const KYC = [
    {id: 1, label: "Mamut"},
]

export const LAND_ROVER = [
    {id: 1, label: "Defender"},
    {id: 2, label: "Discovery"},
    {id: 3, label: "Freelander"},
    {id: 4, label: "Rover"},
    {id: 5, label: "Sport"},
    {id: 6, label: "Evoque"},
    {id: 7, label: "Discovery Sport"},
    {id: 8, label: "Velar"},
]

export const LEXUS = [
    {id: 1, label: "ES"},
    {id: 2, label: "IS"},
    {id: 3, label: "LS"},
    {id: 4, label: "RC"},
    {id: 5, label: "LC"},
    {id: 6, label: "LBX"},
    {id: 7, label: "NX"},
    {id: 8, label: "RX"},
    {id: 9, label: "RZ"},
    {id: 10, label: "TX"},
    {id: 11, label: "UX"},
    {id: 12, label: "GX"},
    {id: 13, label: "LX"},
    {id: 14, label: "LM"},
]

export const LIFAN = [
    {id: 1, label: "320"},
    {id: 2, label: "330"},
    {id: 3, label: "520"},
    {id: 4, label: "530"},
    {id: 5, label: "620"},
    {id: 6, label: "630"},
    {id: 7, label: "650"},
    {id: 8, label: "720"},
    {id: 9, label: "820"},
    {id: 10, label: "Foison"},
    {id: 11, label: "Lotto"},
    {id: 12, label: "Maiwei"},
    {id: 13, label: "X50"},
    {id: 14, label: "X60"},
    {id: 15, label: "X70"},
    {id: 16, label: "X80"},
    {id: 17, label: "Xingshun"},
    {id: 18, label: "Xuanlang"},
]

export const MASERATI = [
    {id: 1, label: "Grecale"},
    {id: 2, label: "Ghibli"},
    {id: 3, label: "Levante"},
    {id: 4, label: "Quattroporte"},
    {id: 5, label: "GranTurismo"},
    {id: 6, label: "MC20"},
    {id: 7, label: "MC20 Cielo"},
    {id: 8, label: "Corse"},
    {id: 9, label: "Futura"},
    {id: 10, label: "Fuoriserie"},
    {id: 11, label: "Folgore"},
    {id: 12, label: "MC Edition"},
    {id: 13, label: "F Tributo"},
    {id: 14, label: "Fragment Design x"},
    {id: 15, label: "X70"},
    {id: 16, label: "X80"},
    {id: 17, label: "Xingshun"},
    {id: 18, label: "Xuanlang"},
]

export const MERCEDES_BENZ = [
    {id: 1, label: "A-Class"},
    {id: 2, label: "C-Class"},
    {id: 3, label: "CLA"},
    {id: 4, label: "CLS"},
    {id: 5, label: "E-Class"},
    {id: 6, label: "EQE"},
    {id: 7, label: "EQS"},
    {id: 8, label: "S-Class"},
    {id: 9, label: "EQB"},
    {id: 10, label: "EQC"},
    {id: 11, label: "EQE SUV"},
    {id: 12, label: "EQS SUV"},
    {id: 13, label: "G-Class"},
    {id: 14, label: "GLA"},
    {id: 15, label: "GLC"},
    {id: 16, label: "GLE"},
    {id: 17, label: "GLS"},
    {id: 18, label: "AMG GT"},
    {id: 19, label: "AMG GT 4-Door Coupé"},
    {id: 20, label: "AMG SL"},
    {id: 21, label: "AMG One"},
    {id: 22, label: "Citan Van"},
    {id: 23, label: "Viano"},
    {id: 24, label: "EQT"},
    {id: 25, label: "EQV"},
    {id: 26, label: "GLS-Class"},
]

export const MINI = [
    {id: 1, label: "First"},
    {id: 2, label: "One"},
    {id: 3, label: "Cooper Hardtop"},
    {id: 4, label: "Cooper Clubman"},
    {id: 5, label: "Cooper Convertible"},
    {id: 6, label: "Cooper S Hardtop"},
    {id: 7, label: "Cooper S Clubman"},
    {id: 8, label: "Cooper S Convertible"},
    {id: 9, label: "Cooper S"},
    {id: 10, label: "Cooper"},
    {id: 11, label: "John Cooper Works"},
    {id: 12, label: "Challenge"},
    {id: 13, label: "One D"},
    {id: 14, label: "Cooper D"},
    {id: 15, label: "Cooper SD"},
    {id: 16, label: "E"},
    {id: 17, label: "Coupé"},
    {id: 18, label: "Roadster"},
    {id: 19, label: "Sorento"},
    {id: 20, label: "Paceman"},
    {id: 21, label: "Mini Clubman"},
    {id: 22, label: "Mini Hatch/Hardtop"},
    {id: 23, label: "5-door Hatch"},
]

export const MITSUBISHI = [
    {id: 1, label: "Colt"},
    {id: 2, label: "Mirage/Space Star"},
    {id: 3, label: "Grand Lancer"},
    {id: 4, label: "Mirage G4/Attrage"},
    {id: 5, label: "Colt Plus"},
    {id: 6, label: "Airtrek"},
    {id: 7, label: "ASX (Europe)"},
    {id: 8, label: "ASX/RVR/Outlander Sport"},
    {id: 9, label: "Eclipse Cross"},
    {id: 10, label: "Outlander"},
    {id: 11, label: "Pajero Sport/Montero Sport"},
    {id: 12, label: "Delica D:2"},
    {id: 13, label: "Delica D:5"},
    {id: 14, label: "Delica"},
    {id: 15, label: "L300 (Philippines)"},
    {id: 16, label: "Xpander"},
    {id: 17, label: "Xpander Cross"},
    {id: 18, label: "Zinger"},
    {id: 19, label: "L200/Triton/Strada"},
    {id: 20, label: "Colt L300 (Indonesia)"},
    {id: 21, label: "Delica (Taiwan)"},
    {id: 22, label: "Zinger Pickup"},
    {id: 23, label: "Delica Mini"},
    {id: 24, label: "eK Space"},
    {id: 25, label: "eK Wagon"},
    {id: 26, label: "eK X EV"},
    {id: 27, label: "Town Box"},
    {id: 28, label: "Minicab MiEV"},
    {id: 29, label: "Minicab Van"},
    {id: 30, label: "Minicab Truck"},
]

export const NISSAN = [
    {id: 1, label: "Leaf"},
    {id: 2, label: "Micra"},
    {id: 3, label: "Note"},
    {id: 4, label: "Tiida(C12)"},
    {id: 5, label: "Tiida(C13)"},
    {id: 6, label: "Almera"},
    {id: 7, label: "Altima"},
    {id: 8, label: "Fuga"},
    {id: 9, label: "Lannia"},
    {id: 10, label: "Maxima"},
    {id: 11, label: "Sentra"},
    {id: 12, label: "Sylphy"},
    {id: 13, label: "Skyline"},
    {id: 14, label: "Ariya"},
    {id: 15, label: "Juke"},
    {id: 16, label: "Kicks"},
    {id: 17, label: "Magnite"},
    {id: 18, label: "Murano"},
    {id: 19, label: "Pathfinder"},
    {id: 20, label: "Patrol"},
    {id: 21, label: "Armada"},
    {id: 22, label: "Qashqai"},
    {id: 23, label: "Rogue"},
    {id: 24, label: "X-Trail"},
    {id: 25, label: "Terra"},
    {id: 26, label: "Terrano"},
    {id: 27, label: "Elgrand"},
    {id: 28, label: "Livina"},
    {id: 29, label: "Primastar"},
    {id: 30, label: "Serena"},
    {id: 31, label: "Townstar"},
    {id: 32, label: "Dayz"},
    {id: 33, label: "Roox"},
    {id: 34, label: "GT-R"},
    {id: 35, label: "Z"},
]

export const PEUGEOT = [
    {id: 1, label: "208"},
    {id: 2, label: "308"},
    {id: 3, label: "301"},
    {id: 4, label: "408"},
    {id: 5, label: "508"},
    {id: 6, label: "2008"},
    {id: 7, label: "3008"},
    {id: 8, label: "5008"},
    {id: 9, label: "Rifter"},
    {id: 10, label: "Traveller"},
    {id: 11, label: "Boxer"},
    {id: 12, label: "Expert"},
    {id: 13, label: "Partner"},
    {id: 14, label: "Landtrek"},
    {id: 15, label: "107"},
    {id: 16, label: "108"},
    {id: 17, label: "206"},
    {id: 18, label: "207"},
    {id: 19, label: "307"},
    {id: 20, label: "407"},
    {id: 21, label: "607"},
    {id: 22, label: "4007"},
    {id: 23, label: "4008"},
    {id: 24, label: "RCZ"},
    {id: 25, label: "iOn"},
    {id: 26, label: "Hoggar"},
    {id: 27, label: "Bipper Tepee"},
]

export const PORSCHE = [
    {id: 1, label: "Boxster"},
    {id: 2, label: "911"},
    {id: 3, label: "Cayenne"},
    {id: 4, label: "Macan"},
    {id: 5, label: "Panamera"},
    {id: 6, label: "Taycan"},
]

export const RAM = [
    {id: 1, label: "3500"},
    {id: 2, label: "Promaster"},
    {id: 3, label: "2500"},
    {id: 4, label: "Promaster City"},
    {id: 5, label: "Chassis Cab"},
    {id: 6, label: "1500"},
    {id: 7, label: "Cargo Van"},
    {id: 8, label: "700"},
    {id: 9, label: "Pickup"},
    {id: 10, label: "4500"},
    {id: 11, label: "5500"},
]

export const RENAULT = [
    {id: 1, label: "Clio"},
    {id: 2, label: "Kwid/City K-ZE/Climber"},
    {id: 3, label: "Mégane"},
    {id: 4, label: "Mégane E-Tech Electric"},
    {id: 5, label: "Sandero"},
    {id: 6, label: "Twingo"},
    {id: 7, label: "Zoe"},
    {id: 8, label: "Taliant"},
    {id: 9, label: "Arkana"},
    {id: 10, label: "Captur"},
    {id: 11, label: "Duster"},
    {id: 12, label: "Austral"},
    {id: 13, label: "Espace"},
    {id: 14, label: "Kiger"},
    {id: 15, label: "Kangoo MPV"},
    {id: 16, label: "Trafic Kombi"},
    {id: 17, label: "Triber"},
    {id: 18, label: "Dokker"},
    {id: 19, label: "Express"},
    {id: 20, label: "Master"},
    {id: 21, label: "Trafic"},
    {id: 22, label: "Duster Oroch"},
    {id: 23, label: "Alaskan"},
]

export const SHINERAY = [
    {id: 1, label: "G01"},
    {id: 2, label: "G01F"},
    {id: 3, label: "G03"},
    {id: 4, label: "G01F"},
    {id: 5, label: "G05"},
    {id: 6, label: "M3"},
    {id: 7, label: "M7"},
    {id: 8, label: "X30"},
    {id: 9, label: "X30L"},
    {id: 10, label: "X30XLS"},
    {id: 11, label: "T1"},
    {id: 12, label: "T3"},
    {id: 13, label: "T5"},
    {id: 14, label: "G03FEDi"},
    {id: 15, label: "X30LEV"},
]

export const SMART = [
    {id: 1, label: "Forfour"},
    {id: 2, label: "BRABUS Forfour"},
    {id: 3, label: "Brabus Tailor Made"},
    {id: 4, label: "BRABUS Fortwo"},
    {id: 5, label: "BRABUS Fortwo Cabrio"},
    {id: 6, label: "Cabrio Tailor Made"},
    {id: 7, label: "EQ Forfour"},
    {id: 8, label: "EQ Fortwo"},
    {id: 9, label: "EQ Fortwo Cabrio"},
    {id: 10, label: "Fortwo"},
    {id: 11, label: "Fortwo Cabrio"},
    {id: 12, label: "Fortwo Special Models"},
    {id: 13, label: "Crossblade"},
    {id: 14, label: "Edition #1"},
    {id: 15, label: "Formore"},
    {id: 16, label: "Fortwo Electric Drive"},
    {id: 17, label: "Roadster"},
]

export const SUBARU = [
    {id: 1, label: "BRZ"},
    {id: 2, label: "Impreza"},
    {id: 3, label: "Justy"},
    {id: 4, label: "Legacy"},
    {id: 5, label: "Levorg"},
    {id: 6, label: "WRX"},
    {id: 7, label: "Ascent"},
    {id: 8, label: "Crosstrek"},
    {id: 9, label: "Forester"},
    {id: 10, label: "Outback"},
    {id: 11, label: "Rex"},
    {id: 12, label: "Solterra"},
]

export const SUZUKI = [
    {id: 1, label: "Alto 800"},
    {id: 2, label: "Alto K10"},
    {id: 3, label: "Baleno"},
    {id: 4, label: "Celerio/Cultus"},
    {id: 5, label: "Ignis"},
    {id: 6, label: "S-Presso"},
    {id: 7, label: "Swift"},
    {id: 8, label: "Wagon R"},
    {id: 9, label: "Ciaz"},
    {id: 10, label: "Dzire/Swift Sedan"},
    {id: 11, label: "Swace"},
    {id: 12, label: "Across"},
    {id: 13, label: "Brezza"},
    {id: 14, label: "Fronx"},
    {id: 15, label: "Grand Vitara"},
    {id: 16, label: "Jimny/Jimny Sierra"},
    {id: 17, label: "SX4 S-Cross"},
    {id: 18, label: "Vitara/Escudo"},
    {id: 19, label: "Xbee"},
    {id: 20, label: "APV"},
    {id: 21, label: "Bolan"},
    {id: 22, label: "Eeco"},
    {id: 23, label: "Ertiga"},
    {id: 24, label: "Landy"},
    {id: 25, label: "Solio"},
    {id: 26, label: "XL6/XL7/Ertiga XL7"},
    {id: 27, label: "Alto"},
    {id: 28, label: "Alto Lapin"},
]

export const TOYOTA = [
    {id: 1, label: "Avalon"},
    {id: 2, label: "Belta"},
    {id: 3, label: "bZ3"},
    {id: 4, label: "Camry"},
    {id: 5, label: "Century"},
    {id: 6, label: "Corolla"},
    {id: 7, label: "Allion"},
    {id: 8, label: "Levin GT"},
    {id: 9, label: "Crown"},
    {id: 10, label: "Etios"},
    {id: 11, label: "Mirai"},
    {id: 12, label: "Prius"},
    {id: 13, label: "Vios/Yaris"},
    {id: 14, label: "Agya/Wigo"},
    {id: 15, label: "Aqua"},
    {id: 16, label: "GR Corolla"},
    {id: 17, label: "Etios"},
    {id: 18, label: "Glanza/Starlet"},
    {id: 19, label: "Passo"},
    {id: 20, label: "Vitz"},
    {id: 21, label: "Yaris"},
    {id: 22, label: "GR Yaris"},
    {id: 23, label: "Aygo X"},
    {id: 24, label: "bZ4X"},
    {id: 25, label: "C-HR"},
    {id: 26, label: "Corolla Cross"},
    {id: 27, label: "Frontlander"},
    {id: 28, label: "Grand Highlander"},
    {id: 29, label: "Harrier"},
    {id: 30, label: "Venza"},
    {id: 31, label: "Kluger"},
    {id: 32, label: "Raize"},
    {id: 33, label: "RAV4"},
    {id: 34, label: "Wildlander"},
    {id: 35, label: "Urban Cruiser"},
    {id: 36, label: "Yaris Cross"},
    {id: 37, label: "4Runner"},
    {id: 38, label: "FJ Cruiser"},
    {id: 39, label: "SW4"},
    {id: 40, label: "Land Cruiser"},
    {id: 41, label: "Rush"},
    {id: 42, label: "Sequoia"},
    {id: 43, label: "Alphard"},
    {id: 44, label: "Vellfire"},
    {id: 45, label: "Avanza"},
    {id: 46, label: "Veloz"},
    {id: 47, label: "Calya"},
    {id: 48, label: "Innova"},
    {id: 49, label: "Noah"},
    {id: 50, label: "Voxy"},
    {id: 51, label: "Roomy"},
    {id: 52, label: "Rumion"},
    {id: 53, label: "Sienna"},
    {id: 54, label: "Sienta"},
    {id: 55, label: "Hilux"},
    {id: 56, label: "Tacoma"},
    {id: 57, label: "Tundra"},
    {id: 58, label: "GR86"},
    {id: 59, label: "Supra"},
    {id: 60, label: "Copen GR Sport"},
]

export const VOLKSWAGEN = [
    {id: 1, label: "Golf"},
    {id: 2, label: "Santana"},
    {id: 3, label: "ID.3"},
    {id: 4, label: "Polo"},
    {id: 5, label: "Up"},
    {id: 6, label: "Arteon"},
    {id: 7, label: "Bora"},
    {id: 8, label: "Jetta/Sagitar/Vento"},
    {id: 9, label: "Lamando"},
    {id: 10, label: "Lavida"},
    {id: 11, label: "Magotan"},
    {id: 12, label: "Passat"},
    {id: 13, label: "Phideon"},
    {id: 14, label: "Virtus/Polo Sedan/Lavida XR"},
    {id: 15, label: "Arteon Shooting Brake"},
    {id: 16, label: "Golf Variant/Sportwagen"},
    {id: 17, label: "Atlas/Teramont"},
    {id: 18, label: "Atlas Cross Sport/Teramont X"},
    {id: 19, label: "ID.4"},
    {id: 20, label: "ID.5"},
    {id: 21, label: "ID.6"},
    {id: 22, label: "T-Cross/Tacqua/Taigun"},
    {id: 23, label: "T-Roc"},
    {id: 24, label: "Taigo/Nivus"},
    {id: 25, label: "Talagon"},
    {id: 26, label: "Taos/Tharu"},
    {id: 27, label: "Tavendor"},
    {id: 28, label: "Tayron"},
    {id: 29, label: "Tayron X"},
    {id: 30, label: "Tiguan"},
    {id: 31, label: "Tiguan Allspace"},
    {id: 32, label: "Tiguan X"},
    {id: 33, label: "Touareg"},
    {id: 34, label: "Sharan"},
    {id: 35, label: "Viloran"},
]

export const VOLVO = [
    {id: 1, label: "S60"},
    {id: 2, label: "V70/XC70"},
    {id: 3, label: "XC90"},
    {id: 4, label: "S40/V50"},
    {id: 5, label: "C30"},
    {id: 6, label: "C70"},
    {id: 7, label: "S80"},
    {id: 8, label: "V70/XC70"},
    {id: 9, label: "XC60"},
    {id: 10, label: "S60/V60"},
    {id: 11, label: "V40"},
    {id: 12, label: "XC Classic"},
    {id: 13, label: "XC90"},
    {id: 14, label: "S90/V90"},
    {id: 15, label: "XC60"},
    {id: 16, label: "XC40"},
    {id: 17, label: "S60/V60"},
    {id: 18, label: "C40"},
    {id: 19, label: "EX30"},
    {id: 20, label: "EX90"},
]