export const YEAR_ROUTE_REDIRECTION = "/brandselect";
export const BRAND_ROUTE_REDIRECTION = "/modelselect";
export const MODEL_ROUTE_REDIRECTION = "/versionselect";
export const VERSION_ROUTE_REDIRECTION = "/fuelselect";
export const FUEL_ROUTE_REDIRECTION = "/doorselect";
export const DOOR_ROUTE_REDIRECTION = "/transmissionselect";
export const TRANSMISSION_ROUTE_REDIRECTION = "/engineselect";
export const ENGINE_ROUTE_REDIRECTION = "/bodyselect";
export const BODY_ROUTE_REDIRECTION = "/colorselect";
export const COLOR_ROUTE_REDIRECTION = "/kmselect";
export const KM_ROUTE_REDIRECTION = "/contact";
export const ERROR_ROUTE_REDIRECTION = "/errorselect";
export const SUCCESS_ROUTE_REDIRECTION = "/congratzselect";
export const SUCCESS_BUY_ROUTE_REDIRECTION = "/comprar";